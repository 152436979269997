import { MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import Navbar from "../Collapse-Nav/navbar";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import NotFound from "../../Pages/notFound";
import CommingSoon from "../../Pages/commingSoon";
import TopHeader from "../Top-Header/topHeader";
import { Button } from "@mui/material";
import { AiOutlineLogout } from "react-icons/ai";
import Roles, { ManagerAdminRole } from "./roles";
import UnAuthorized from "../../Pages/unAuthorized";
import React from "react";
// import { lazy } from "react";
import Dashboard from "../../Pages/dashboard";
import Restaurant from "../../Pages/restaurant/restaurant";
import Profile from "../../Pages/profile/profile";
import Orders from "../../Pages/order/orders";
import Couriers from "../../Pages/courier/couriers";
import Reports from "../../Pages/reports/reports";
import EditRestaurant from "../../Pages/restaurant/Resturants-Components/editRestaurant";
import AddRestaurant from "../../Pages/restaurant/Resturants-Components/addResturant";
import RestaurantDetails from "../../Pages/restaurant/Resturants-Components/restaurantDetails";
import Accounts from "../../Pages/Accounts/accounts";
import Users from "../../Pages/users/users";
import RestaurantOrders from "../../Pages/restaurant/Resturants-Components/restaurantOrders";
import OrderDetails from "../../Pages/order/order-components/orderDetails";
import CourierDetails from "../../Pages/courier/Couries-Components/courierDetails";
import Troubleshoot from "../../Pages/Troubleshoot/troubleshoot";
import TicketDetails from "../../Pages/Troubleshoot/troubleTicketDetails";
import NewPasswordRole from "./newPasswordRole";
import Delivery from "../../Pages/Delivery/delivery";
import { getMapKey } from "../Redux/Slice/common/mapSlice";
import { useTranslation } from "react-i18next";
import AccManager, { EvictRole } from "./accManagerRole";
import Branches from "../PartnerDashboard/branch/branches";
import Menu from "../PartnerDashboard/menu/menu";
import AddNewDish from "../PartnerDashboard/menu/Menu-Components/addNewDish";
import EditDish from "../PartnerDashboard/menu/Menu-Components/editDish";
import Offers from "../PartnerDashboard/offers/offers";
import Deals from "../PartnerDashboard/deals/deals";
import AddNewDeal from "../PartnerDashboard/deals/Deals-Components/addDeal";
import EditDeal from "../PartnerDashboard/deals/Deals-Components/editDeal";
import Settings from "../PartnerDashboard/settings/settings";
import ManagerDashboard from "../PartnerDashboard/dashboard/managerDashboard";
import DeliveryContractors from "../../Pages/DeliveryContractors/deliveryContractors";
import Coupons from "../../Pages/Coupon/coupons";
import PartnerUsers from "../PartnerDashboard/Users/partnerUsers";
import Rating from "../PartnerDashboard/Rating/rating";
import PartnerReport from "../PartnerDashboard/reports/reports";
import DeliveryPackages from "../../Pages/DeliveryPackages/deliveryPackages";
import PartnersDeliveryPackages from "../PartnerDashboard/DeliveryPackages/deliveryPackages";
import CourierTransactions from "../../Pages/courier/Couries-Components/courierTransaction";
import RestaurantTransactions from "../../Pages/restaurant/Resturants-Components/restaurantTransactions";
import Payment from "../../Pages/Payment/payment";
import ContractorTransactions from "../../Pages/DeliveryContractors/Contractors-Components/contractorTransactions";
import Banks from "../../Pages/Banks/banks";
import DeliveryMetrics from "../../Pages/reports/metrics/deliveryMetrics";

// const Dashboard = lazy(() => import("../../Pages/dashboard"));
// const restaurant = lazy(() => import("../../Pages/restaurant/restaurant"));
// const Profile = lazy(() => import("../../Pages/profile/profile"));
// const Orders = lazy(() => import("../../Pages/order/orders"));
// const Couriers = lazy(() => import("../../Pages/courier/couriers"));
// const Reports = lazy(() => import("../../Pages/reports/reports"));
// const EditRestaurant = lazy(() => import("../../Pages/restaurant/restaurants-Components/editRestaurant"));
// const AddRestaurant = lazy(() => import("../../Pages/restaurant/restaurants-Components/addrestaurant"));
// const Accounts = lazy(() => import("../../Pages/accounts"));
// const Users = lazy(() => import("../../Pages/users"));
// const restaurantOrders = lazy(() => import("../../Pages/restaurant/restaurants-Components/restaurantOrders"));
// const OrderDetails = lazy(() => import("../../Pages/order/order-components/orderDetails"));
// const RestaurantDetails = lazy(() => import("../../Pages/restaurant/restaurants-Components/restaurantDetails"));
// const CourierDetails = lazy(() => import("../../Pages/courier/Couries-Components/courierDetails"));


const MainRoutes = () => {
    const { t, i18n } = useTranslation()
    const { token } = useSelector(state => state?.auth)
    const { role } = useSelector(state => state?.auth)
    const dispatch = useDispatch()
    // console.log(role);
    const navigate = useNavigate()
    // console.log(token);
    const path = useLocation()
    const [navCollapse, setnavCollapse] = useState(false)

    const toggleNavHanlder = () => {
        let toggle = navCollapse
        setnavCollapse(!toggle)
    }
    // console.log(path.pathname !== '/dashboard/notfound');
    // useEffect(() => {
    //     if((!localStorage.getItem('atk') && token == null) && (path.pathname !== '/dashboard/notfound')&& (path.pathname !== '/dashboard/commingsoon')){
    //         navigate('/login')
    //     }
    // }, [])

    const [isLoggedout, setisLoggedout] = useState(false)

    const logoutHandler = () => {
        localStorage.clear()
        // Object.keys(localStorage).forEach((itemKey) => {
        //     if (itemKey !== 'gkey') {
        //         localStorage.removeItem(itemKey);
        //     }
        // });
        navigate('/login')
        i18n.changeLanguage('en');
        setisLoggedout(true)
    }

    // MAP Handler
    useEffect(() => {
        dispatch(getMapKey())
    }, [])

    return (
        <div dir={localStorage?.getItem('lang') === 'ar' ? 'rtl' : 'ltr'} className={`takein-wrapper flex h-full`}>
            {/* <section className={`navbar-collapse overflow-x-hidden transition-all basis-auto duration-300 ${navCollapse ? 'w-[70px] min-w-[0px] max-w-[70px]' : 'w-[70px] min-w-[200px] max-w-[300px]'} h-full bg-mainColor relative`}> */}
            <section className={`navbar-collapse overflow-x-hidden transition-all basis-auto duration-300 ${navCollapse ? 'w-[65px] min-w-[65px] max-w-[65px]' : 'min-w-[210px] max-w-[210px]'} h-full bg-mainColor relative`}>
                <p className={`text-white text-3xl p-2 cursor-pointer ${localStorage?.getItem('lang') === 'ar' ? '[&>svg]:mr-auto' : '[&>svg]:ml-auto'}`} onClick={toggleNavHanlder}>
                    {localStorage?.getItem('lang') === 'ar' ?
                        navCollapse ? <MdOutlineKeyboardDoubleArrowLeft /> : <MdOutlineKeyboardDoubleArrowRight />
                        :
                        navCollapse ? <MdOutlineKeyboardDoubleArrowRight /> : <MdOutlineKeyboardDoubleArrowLeft />
                    }

                </p>

                <div className="flex flex-col justify-between w-full h-[93%]">
                    <Navbar toggle={navCollapse} />

                    <div className={`text-white text-[17px] w-full px-1`}>
                        <Button onClick={logoutHandler} className={`flex justify-start items-center [&>span]:block [&>span]:w-full  w-full text-white text-start`}><span className="[&>svg]:inline-block [&>svg]:w-[27px] [&>svg]:h-[27px] mr-3"><AiOutlineLogout /> {navCollapse ? "" : localStorage.getItem('lang') === 'ar' ? 'تسجيل الخروج' : 'logout'}</span></Button>
                    </div>
                </div>
            </section>

            <section className='content-wrapper h-full basis-auto grow overflow-y-auto px-2 sm:px-6 relative'>
                <div className="topheader-wrapper sticky top-0 bg-[#F2F0F6] w-full z-[999] py-4">
                    <TopHeader />
                </div>

                <div>
                    {isLoggedout ? null :
                        <Routes>
                            <Route element={<NewPasswordRole />}>

                                <Route path='/dashboard/profile' element={<Profile />} />
                                <Route path='/dashboard/orders' element={<Orders />} />
                                <Route path='/dashboard/orders/orderdetails' element={<OrderDetails />} />
                                <Route path='/dashboard/deliverypackages' element={<DeliveryPackages />} />
                                {/* <Route element={<ManagerAdminRole />}>
                                    <Route path='/dashboard/orders' element={<Orders />} />
                                    <Route path='/dashboard/orders/orderdetails' element={<OrderDetails />} />
                                </Route> */}



                                <Route element={<Roles />}>
                                    <Route path='/dashboard/home' element={<Dashboard />} />
                                    <Route path='/dashboard/reports' element={<Reports />} />
                                    <Route path='/dashboard/reports/deliveryMetrics' element={<DeliveryMetrics />} />
                                    <Route path='/dashboard/users' element={<Users />} />
                                    <Route path='/dashboard/accounts' element={<Accounts />} />
                                    <Route path='/dashboard/deliverycontractors' element={<DeliveryContractors />} />
                                    <Route path='/dashboard/deliverycontractors/transactions' element={<ContractorTransactions />} />
                                    <Route path='/dashboard/coupons' element={<Coupons />} />
                                    <Route path='/dashboard/users' element={<Users />} />
                                    <Route path='/dashboard/payments' element={<Payment />} />
                                    <Route path='/dashboard/banks' element={<Banks />} />
                                    {/* <Route path='/dashboard/offers' element={<Offers />} /> */}
                                    {/* <Route path='/dashboard/appAds' element={<AppAds />} /> */}
                                </Route>

                                <Route element={<AccManager />}>
                                    <Route path='/dashboard/partner/home' element={<ManagerDashboard />} />
                                    <Route path='/dashboard/partner/branches' element={<Branches />} />
                                    <Route path='/dashboard/partner/menu' element={<Menu />} />
                                    <Route path='/dashboard/partner/menu/addnewdish' element={<AddNewDish />} />
                                    <Route path='/dashboard/partner/menu/editdish' element={<EditDish />} />
                                    <Route path='/dashboard/rating' element={<Rating />} />
                                    <Route path='/dashboard/partner/users' element={<PartnerUsers />} />
                                    <Route path='/dashboard/partner/deliverypackages' element={<PartnersDeliveryPackages />} />
                                    <Route path='/dashboard/partner/reports' element={<PartnerReport />} />
                                    <Route path='/dashboard/partner/offers' element={<Offers />} />
                                    <Route path='/dashboard/partner/deals' element={<Deals />} />
                                    <Route path='/dashboard/partner/deals/addnewdeal' element={<AddNewDeal />} />
                                    <Route path='/dashboard/partner/deals/editdeal' element={<EditDeal />} />
                                    <Route path='/dashboard/partner/settings' element={<Settings />} />
                                </Route>

                                <Route element={<EvictRole />}>
                                    <Route path='/dashboard/restaurant' element={<Restaurant />} />
                                    <Route path='/dashboard/restaurant/orders' element={<RestaurantOrders />} />
                                    <Route path='/dashboard/restaurant/addrestaurant' element={<AddRestaurant />} />
                                    <Route path='/dashboard/restaurant/editrestaurant' element={<EditRestaurant />} />
                                    <Route path='/dashboard/restaurant/details' element={<RestaurantDetails />} />
                                    <Route path='/dashboard/restaurant/transactions' element={<RestaurantTransactions />} />

                                    <Route path='/dashboard/deliverycharges' element={<Delivery />} />
                                    <Route path='/dashboard/couriers' element={<Couriers />} />
                                    <Route path='/dashboard/couriers/details' element={<CourierDetails />} />
                                    <Route path='/dashboard/couriers/transactions' element={<CourierTransactions />} />
                                    <Route path='/dashboard/troubleshoots' element={<Troubleshoot />} />
                                    <Route path='/dashboard/troubleshoots/details' element={<TicketDetails />} />
                                </Route>

                                <Route path='*' element={<NotFound />} /> {/* this path used to display this component when ever there is no match to any route */}
                                <Route path='/dashboard/unauthorized' element={<UnAuthorized />} />
                                <Route path='/dashboard/ads' element={<CommingSoon />} />
                            </Route>

                        </Routes>
                    }
                </div>
            </section>
        </div>

    );
}

export default MainRoutes;